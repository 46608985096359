/* .dataTable {
    height: 600px;
} */

.datagrid {
    color: #545F7D;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-style: normal;
    /* margin: 1rem; */
    border: none;
}

.MuiDataGrid-toolbarContainer {
    padding: .75rem;
}

.invalidPoNumber {    
    color: red;
}

.isStuck {
    text-decoration: underline;
}

.cellWithStatus {
    padding: 2px 5px;
    border-radius: 2px;
    text-align: center;
    min-width: 4.25rem;
    /* background-color: rgba(0, 0, 0, 0.02);
    color: gray; */
}

/* .Estimated.Delivery {
    background-color: rgba(0, 0, 0, 0.05);
    color: gray;
} */

.Delivered,
.Arrived.Location,
.Available.Delivery,
.Completed.Unloading {
    background-color: #EBFBE4;
    color: #568B36;
}

.In.Transit,
.Transit,
.Estimated.Delivery,
.Out.Delivery,
.Tendered.Delivery,
.En.Route,
.Connecting.Line {
    background-color: #f0f8ff;
    color: #2c6fb2;
}

.ready, .Ready {
    color: #2c6fb2;
    text-transform: capitalize;
}

.Draft, .Draft {
    background-color: #cfcfcf46;
    text-transform: capitalize;
}

.Actual.Pickup,
.Carrier.Departed,
.Estimated.Arrive,
.Delivery.Appointment,
.Estimated.Date,
.Loaded.Truck,
.Pickup.Appointment {
    background-color: #fbfbe4;
    color: #b2a02c;
}

.Delayed,
.Attempted.Delivery,
.Unable.to.Locate {
    background-color: rgba(255, 0, 0, 0.05);
    color: orangered;
}

.text--cell {
    text-transform: uppercase;
}