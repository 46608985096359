.ruleGroup-addGroup {
  display: flex;  
  margin-left: auto;  
  margin: 0;  
}

.ruleGroup-addRule {  
  display: flex;  
  margin-left: auto;    
}

.queryBuilder-branches {    
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
}
  
.queryBuilder-branches .query-builder {
  font-size: 14px;
  color: #333;
}

.ruleGroup {
  background-color: #363535bf;      
}